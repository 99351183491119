var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "div",
        { staticClass: "elementPanel" },
        [
          _vm.isLoading
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("LoaderCard", {
                        attrs: {
                          flat: "",
                          type: "spinner--center",
                          minHeight: "300px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? [
                _c(
                  "div",
                  { staticClass: "text-xs-right" },
                  [
                    !_vm.report.isClosed &&
                    _vm.allowEdit &&
                    !_vm.report.isReplanning &&
                    !_vm.report.isOffline()
                      ? [
                          _vm.user.hasRole(_vm.userRoles.ManagerRoles)
                            ? _c(
                                "MiButton",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    condensed: "",
                                    color: "primary",
                                    small: "true",
                                    icon: "redo"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openInvestigationDialog(
                                        "followUpInvestigation"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Vervolgopname ")]
                              )
                            : _vm._e(),
                          _vm.user.hasRole(_vm.userRoles.ManagerRoles) &&
                          !_vm.report.isVesNulmetingReport
                            ? _c(
                                "MiButton",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    condensed: "",
                                    color: "primary-light",
                                    small: "true",
                                    icon: "search"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openInvestigationDialog(
                                        "furtherInvestigation"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Nader onderzoek ")]
                              )
                            : _vm._e(),
                          _vm.user.hasRole(_vm.userRoles.ManagerRoles) &&
                          !_vm.report.isVesNulmetingReport
                            ? _c(
                                "MiButton",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    condensed: "",
                                    color: "secondary",
                                    small: "true",
                                    icon: "search"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openInvestigationDialog(
                                        "moreInvestigation"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Aanvullend onderzoek ")]
                              )
                            : _vm._e(),
                          _vm.user.hasRole(_vm.userRoles.ManagerRoles) &&
                          !_vm.report.isVesNulmetingReport
                            ? _c(
                                "MiButton",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    condensed: "",
                                    color: "primary-dark",
                                    small: "true",
                                    icon: "restore"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openInvestigationDialog(
                                        "reInvestigation"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Heropname ")]
                              )
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm.report.isClosed &&
                    _vm.allowEdit &&
                    _vm.$store.state.isServiceOrganization &&
                    !_vm.report.isVesNulmetingReport
                      ? [
                          _vm.user.hasRole(_vm.userRoles.ManagerRoles)
                            ? _c(
                                "MiButton",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    condensed: "",
                                    color: "primary-dark",
                                    small: "true",
                                    icon: "restore"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openConfirmHeropnameDialog(
                                        $event
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Heropname Aanmaken ")]
                              )
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm.$store.state.isServiceOrganization && _vm.allowEdit
                      ? [
                          !_vm.report.isClosed &&
                          _vm.user.hasRole([
                            "admin",
                            "manager",
                            "tcmg",
                            "werkvoorbereiding",
                            "planning",
                            "serviceloket"
                          ])
                            ? _c(
                                "MiButton",
                                {
                                  attrs: {
                                    condensed: "",
                                    color: "warning",
                                    small: "true",
                                    icon: "settings_backup_restore"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.callBackReport($event)
                                    }
                                  }
                                },
                                [_vm._v(" Intrekken ")]
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                !_vm.isLoading
                  ? _c("ReportAppointment", {
                      attrs: {
                        report: _vm.report,
                        enableEditing: _vm.allowEdit,
                        dmzForExperts: true
                      },
                      on: { isEditingEventExpert: _vm.isEditingEventExpert }
                    })
                  : _vm._e(),
                _c("ReportVideoAppointment", { attrs: { report: _vm.report } }),
                !_vm.isLoading
                  ? _c("ReportAppointmentCommunication", {
                      attrs: { report: _vm.report }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.isCreatingInvestigation
            ? _c(
                "v-dialog",
                {
                  attrs: { "min-width": "80%" },
                  model: {
                    value: _vm.isCreatingInvestigation,
                    callback: function($$v) {
                      _vm.isCreatingInvestigation = $$v
                    },
                    expression: "isCreatingInvestigation"
                  }
                },
                [
                  _c(
                    "InvestigationDialog",
                    {
                      attrs: { type: _vm.type, report: _vm.report },
                      on: { submitted: _vm.submitted },
                      model: {
                        value: _vm.isCreatingInvestigation,
                        callback: function($$v) {
                          _vm.isCreatingInvestigation = $$v
                        },
                        expression: "isCreatingInvestigation"
                      }
                    },
                    [
                      _c("template", { slot: "content" }, [
                        _c(
                          "div",
                          { staticClass: "v-card__text scrollContainer" },
                          [
                            _vm.report
                              ? _c("DynamicForm", {
                                  attrs: {
                                    reportId: _vm.report.uuid,
                                    reportType: _vm.report.type.uuid,
                                    planningQuestions: true,
                                    sections: [123],
                                    appendKeys: _vm.questions,
                                    hasAutosave: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isEditingAppointment
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "30vw", fullscreen: true },
                  model: {
                    value: _vm.isEditingAppointment,
                    callback: function($$v) {
                      _vm.isEditingAppointment = $$v
                    },
                    expression: "isEditingAppointment"
                  }
                },
                [
                  _c("ReplanExpertCalendarDialog", {
                    attrs: { event: _vm.selectedEvent, report: _vm.report },
                    on: {
                      updated: function($event) {
                        return _vm.$emit("reload")
                      }
                    },
                    model: {
                      value: _vm.isEditingAppointment,
                      callback: function($$v) {
                        _vm.isEditingAppointment = $$v
                      },
                      expression: "isEditingAppointment"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }