import { Event } from '@/models/Event';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Report as ReportModel } from '@/models/Report';
import ReportAppointment from '@/components/report-appointment/ReportAppointment.vue';
import ReportAppointmentCommunication from '@/components/report-appointment-communication/ReportAppointmentCommunication.vue';
import { AxiosError } from 'axios';
import { User, userRoles } from '@/models/User';
import InvestigationDialog from '@/views/Reports/investigation-dialog/InvestigationDialog.vue';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Rpc } from '@/models/Rpc';
import { ReportTypes } from '@/support/ReportTypes';
import ReplanExpertCalendarDialog from '@/components/dialog/replan-expert-calendar-dialog/ReplanExpertCalendarDialog.vue';
import ReportVideoAppointment from '@/components/report-video-appointment/ReportVideoAppointment.vue';

@Component<Planning>({
  components: {
    ReportAppointment,
    ReportVideoAppointment,
    ReportAppointmentCommunication,
    InvestigationDialog,
    ReplanExpertCalendarDialog,
  },
})
export default class Planning extends Vue {
  public $pageTitle = 'Planning';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  protected isLoading = true;

  protected isEditingAppointment = false;

  @Prop({ default: true })
  protected allowEdit!: boolean;

  protected user: User = new User();

  protected type = '';

  protected isCreatingInvestigation = false;

  protected userRoles = userRoles;

  protected selectedEvent: Event | null = null;

  protected questions: string[] = [];

  public questionsMap: {[key: string]: string[]} = {
    followUpInvestigation: [
      'HerplannenTijdsduur',
      'HerplannenAanleiding',
      'HerplannenBenodigd',
    ],
    furtherInvestigation: [
      'HerplannenTijdsduur',
      'HerplannenAanleiding',
      'HerplannenBenodigd',
      'HerplannenKosten',
    ],
    moreInvestigation: [
      'HerplannenTijdsduur',
      'HerplannenAanleiding',
      'HerplannenBenodigd',
    ],
    reInvestigation: [
      'HerplannenTijdsduur',
      'HerplannenAanleiding',
      'HerplannenBenodigd',
    ],
  };

  public payloadMap: {[key: string]: {[key: string]: any}} = {
    followUpInvestigation: {
      status: 'back_to_planning',
      answers: {
        HerplannenType: 'vervolgopname',
      },
      planning: {
        status: 'vervolgopname',
      },
    },
    furtherInvestigation: {
      status: 'pending_sb_approval',
      answers: {
        HerplannenType: 'nader_onderzoek',
      },
    },
    moreInvestigation: {
      status: 'pending_sb_approval',
      answers: {
        HerplannenType: 'aanvullend_onderzoek',
      },
    },
    reInvestigation: {
      status: 'pending_sb_approval',
      answers: {
        HerplannenType: 'heropname',
      },
    },
  };

  public bezwaarPayloadMap: {[key: string]: {[key: string]: any}} = {
    followUpInvestigation: {
      status: 'objection_werkvoorbereiding_rejected',
      answers: {
        HerplannenType: 'vervolgopname',
      },
      planning: {
        status: 'vervolgopname',
      },
    },
    furtherInvestigation: {
      status: 'objection_werkvoorbereiding_rejected',
      answers: {
        HerplannenType: 'nader_onderzoek',
      },
      planning: {
        status: 'nader_onderzoek',
      },
    },
    moreInvestigation: {
      status: 'objection_werkvoorbereiding_rejected',
      answers: {
        HerplannenType: 'aanvullend_onderzoek',
      },
      planning: {
        status: 'aanvullend_onderzoek',
      },
    },
    reInvestigation: {
      status: 'objection_werkvoorbereiding_rejected',
      answers: {
        HerplannenType: 'heropname',
      },
      planning: {
        status: 'heropname',
      },
    },
  };

  protected mounted() {
    this.user = this.$store.state.Auth;
    this.isLoading = false;
  }

  protected openInvestigationDialog(type: string) {
    this.type = type;
    this.questions = this.questionsMap[type];
    this.isCreatingInvestigation = true;
  }

  protected callBackReport() {
    if (! this.$route.params.id) { return; }
    this.$store.dispatch('openDialog', this.dialogOptionsCallBackReport);
  }

  protected async submitted() {
    this.isLoading = true;
    const payload = this.report.isBezwaarReport ? this.bezwaarPayloadMap : this.payloadMap;

    await new ReportModel(this.report)
      .update(payload[this.type])
      .then(() => {
        this.$emit('reload');
      });

    this.isLoading = false;
  }

  protected openConfirmHeropnameDialog() {
    this.$store.dispatch('openDialog', this.confirmHeropnameDialogOptions);
  }

  protected isEditingEventExpert(event: Event) {
    this.selectedEvent = event;
    this.isEditingAppointment = true;
  }

  protected get confirmHeropnameDialogOptions(): Options {
    return {
      title: 'Heropname aanmaken',
      text: 'Weet je zeker dat je een heropname wilt aanmaken voor dit dossier?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja, aanmaken',
          action: () => {
            this.createHeropname();
          },
        },
        cancel: {
          text: 'Annuleren',
          color: 'text-light',
          action: () => {},
        },
      },
    };
  }

  protected get isObjection(): boolean {
    if (! this.report.type) {
      return true;
    }

    if (this.$store.state.Auth.isAtabix) {
      return false;
    }

    return this.report.type.uuid === ReportTypes.ADVIESRAPPORT_BEZWAAR
    || this.report.type.uuid === ReportTypes.ADVIESRAPPORT_BEZWAAR_AMBTELIJK
    || this.report.type.uuid === ReportTypes.ADVIESRAPPORT_BEZWAAR_ADDENDUM
    || this.report.type.uuid === ReportTypes.ADVIESRAPPORT_BEZWAAR_HOORZITTING
    || this.report.type.uuid === ReportTypes.BEZWAAR_2021
    || this.report.type.uuid === ReportTypes.BEZWAAR_AMBTELIJK_2
    || this.report.type.uuid === ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE
    || this.report.type.uuid === ReportTypes.BEZWAAR_BAC_2
    || this.report.type.uuid === ReportTypes.BEZWAAR_BAC_2_CALCULATIE;
  }

  protected createHeropname() {
    new Rpc()
      .rpcPost({
        signature: 'heropname:create',
        body: {
          report: this.report.case_number,
        },
      })
      .then(() => {
        this.$emit('reload');
      });
  }

  protected get dialogOptionsCallBackReport(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet u zeker dat u dit rapport wilt intrekken ?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja, rapport intrekken',
          action: () => {
            const payload: any = {
              signature: 'planning:cancel',
              body: {
                sync: true,
                report: this.$route.params.id || '',
              },
            };

            new Rpc()
              .rpcPost(payload)
              .then((response: any) => {
                this.$store.dispatch('openDialog', this.dialogOptionsCallBackReportSuccess);
                this.$emit('reload');
              })
              .catch((error: AxiosError) => {
                this.$store.dispatch('openDialog', this.dialogOptionsCallBackReportError);
                this.$emit('reload');
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get dialogOptionsCallBackReportSuccess(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Het rapport is ingetrokken',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  protected get dialogOptionsCallBackReportError(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Het rapport kan niet meer worden ingetrokken',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }
}
